/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react"
import PropTypes from "prop-types"
import { ComSvgs } from "./svgs"
import { isInWx } from "../utils/utils"
// import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import SideBar from "./sideBar"
import TopSearchBar from "./top_search_bar"
import "./css/layout.css"
import "./css/common.css"

const Layout = function ({ children, cur, showSBar, bg, stickyHeader }) {
  const isWx = isInWx()
  let isMobile = false
  if (typeof window !== "undefined") {
    isMobile = document.documentElement.clientWidth <= 1140
  }

  return (
    <>
      <ComSvgs />
      <Header cur={cur} sticky={stickyHeader} isWx={isWx} isMobile={isMobile} />
      {isMobile && <TopSearchBar topBar />}
      <main style={{ backgroundColor: bg || "#fff" }}>{children}</main>
      <Footer isMobile={isMobile} isWx={isWx} />
      {/* {showSBar && !isMobile && ( */}
      { !isMobile && (
        <SideBar wrapClass="fixRight" isWx={isWx} isMobile={isMobile} />
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
