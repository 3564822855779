import React, { Fragment, useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import classNames from "classnames"

import { CDNBASE } from "../utils/maps"
import st from "./css/sideBar.module.scss"
import Search from "./search"

const sideMenu = [
  { path: "/price/", name: "价格" },
  { path: "/features/", name: "功能介绍" },
  { path: "/fqa/", name: "常见问题" },
  { path: "/aboutus.html", name: "关于我们" },
]

const SideBar = ({ cur, wrapClass, isMobile, isWx }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  if (!isMobile) {
    return (
      <div className={classNames(st[wrapClass])}>
        {wrapClass === "fixRight" ? (
          <>
            <span className={st.iconWrap}>
              <svg className={st.barIcon}>
                <use
                  className={st.svgUse}
                  xlink="https://www.w3.org/1999/xlink"
                  href="#gzh"
                ></use>
              </svg>
              <div className={st.iDetail}>
                <img
                  className={st.qrcode}
                  src={CDNBASE + "/src/qrcode/gzh_dark.gif"}
                  alt="酷课公众号"
                />
                <div>
                  <b>关注酷课公众号</b>
                  <br />
                  开始免费试用
                </div>
              </div>
            </span>
            <span className={st.iconWrap}>
              <svg className={st.barIcon}>
                <use
                  xlink="https://www.w3.org/1999/xlink"
                  href="#minipro"
                ></use>
              </svg>
              <div className={st.iDetail}>
                <img
                  className={st.qrcode}
                  src={CDNBASE + "/src/qrcode/kuku_demo_dark.gif"}
                  alt="酷课约课小程序码"
                />
                <div>
                  <b>学员端演示版</b>
                  <br />
                  微信扫码体验
                </div>
              </div>
            </span>
            <span className={st.iconWrap}>
              <svg className={st.barIcon}>
                <use
                  xlink="https://www.w3.org/1999/xlink"
                  href="#wechat"
                ></use>
              </svg>
              <div className={classNames(st.iDetail, st.toleft)}>
                <img
                  className={st.qrcode}
                  src={CDNBASE + "/src/qrcode/kumei_dark.gif"}
                  alt="酷课客服微信"
                />
                <div>
                  <b>客服微信</b>
                  <br />
                  微信扫码联系客服
                </div>
              </div>
            </span>
            <span className={st.iconWrap}>
              <svg className={st.barIcon}>
                <use
                  xlink="https://www.w3.org/1999/xlink"
                  href="#contact"
                ></use>
              </svg>
              <div
                className={classNames(st.iDetail, st.toleft)}
                style={{ textAlign: "left" }}
              >
                <h4>客服电话：</h4>
                <h5>小静：159 2720 4971</h5>
                <h5>小梅：134 3712 8855</h5>
              </div>
            </span>
          </>
        ) : (
          <Search navBar />
        )}
        {/* <Link
          target="_blank"
          className={st.go}
          to="https://openai.weixin.qq.com/webapp/Iw407iuJXN8HELHiBIsEJ3o0KNS3cC?robotName=%E9%85%B7%E9%85%B7"
        >
          在线咨询
        </Link> */}
        <Link className={st.go} target="_blank" to="https://kukecloud.com/adm/">
          开始使用
        </Link>
      </div>
    )
  } else {
    return (
      <Fragment>
        <div className={classNames(st.inNavBar)}>
          {/* <Link
            className={st.go}
            to="https://openai.weixin.qq.com/webapp/Iw407iuJXN8HELHiBIsEJ3o0KNS3cC?robotName=%E9%85%B7%E9%85%B7"
          >
            在线咨询
          </Link> */}
          <span className={st.iconWrap} onClick={() => setDrawerOpen(true)}>
            <svg className={st.barIcon}>
              <use
                className={st.svgUse}
                xlink="https://www.w3.org/1999/xlink"
                href="#menu"
              ></use>
            </svg>
          </span>
        </div>

        <div
          className={classNames("drawer", "drawer-left", {
            ["drawer-open"]: drawerOpen,
          })}
        >
          <div className="drawer-bg" onClick={() => setDrawerOpen(false)}></div>
          <div
            className="drawer-content-wrapper"
            style={{
              width: "60vw",
              left: "-60vw",
              transform: drawerOpen ? "translateX(60vw)" : undefined,
            }}
          >
            <div className="drawer-content">
              <aside className={st.sideMenu}>
                <div className={st.navItems}>
                  {sideMenu.map(v => (
                    <Link
                      key={v.path}
                      className={classNames(st.navItem, {
                        [st.cur]: cur === v.path,
                      })}
                      to={v.path}
                    >
                      {v.name}
                    </Link>
                  ))}
                </div>

                <div className={st.qrcodeItems}>
                  {isWx && (
                    <div className={st.qrcodeItem}>
                      <img
                        className={st.qrcode}
                        src={CDNBASE + "/src/qrcode/user-intro-ying.png"}
                        alt="酷课公众号"
                      />
                      <div>
                        <b>开始免费试用</b>
                        <br />
                        长按进入注册页面
                      </div>
                    </div>
                  )}
                  <div className={st.qrcodeItem}>
                    <img
                      className={st.qrcode}
                      src={CDNBASE + "/src/qrcode/gzh_dark.gif"}
                      alt="酷课公众号"
                    />
                    <div>
                      <b>酷课公众号</b>
                      <br />
                      {isWx ? "长按进入酷课公众号" : "长按保存酷课公众号"}
                    </div>
                  </div>
                  <div className={st.qrcodeItem}>
                    <img
                      className={st.qrcode}
                      src={CDNBASE + "/src/qrcode/kumei_dark.gif"}
                      alt="酷课客服微信"
                    />
                    <div>
                      <b>添加客服微信</b>
                      <br />
                      {isWx ? "长按进入客服名片" : "长按保存客服微信名片"}
                    </div>
                  </div>
                </div>
                <div className={st.contact}>
                  <div className={st.contactItem}>
                    <a href="tel:15927204971">
                      <svg className={st.telIcon}>
                        <use
                          xlink="https://www.w3.org/1999/xlink"
                          href="#contact"
                        ></use>
                      </svg>{" "}
                      <span>拨打客服小静</span>
                    </a>
                  </div>
                  <div className={st.contactItem}>
                    <a href="tel:13437128855">
                      <svg className={st.telIcon}>
                        <use
                          xlink="https://www.w3.org/1999/xlink"
                          href="#contact"
                        ></use>
                      </svg>{" "}
                      <span>拨打客服小梅</span>
                    </a>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

SideBar.propTypes = {
  wrapClass: PropTypes.string,
}

SideBar.defaultProps = {
  wrapClass: `inNavBar`,
}

export default SideBar
